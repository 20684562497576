import { APICore } from './apiCore';

const api = new APICore();
export function createCompanyApi(params: {}) {
    const baseUrl = '/account/company/';
    return api.create(`${baseUrl}`, params);
}

export function getAccountLinkApi(id: string) {
    const baseUrl = `/account/company/${id}/link`;
    return api.get(`${baseUrl}`, null);
}

export function updateCompanyApi(params: {}) {
    const baseUrl = `/account/company/update`;
    return api.create(`${baseUrl}`, params);
}

export function checkShopifyApi() {
    const baseUrl = `/account/company/shopify/status`;
    return api.get(`${baseUrl}`, null);
}

export function getShopifyProductsApi() {
    const baseUrl = `/account/company/shopify/products`;
    return api.get(`${baseUrl}`, null);
}

export function connectShopifyApi(data: any) {
    const baseUrl = `/account/company/shopify/connect`;
    return api.create(`${baseUrl}`, data);
}
