import Routes from 'routes/Routes';

import 'assets/scss/Modern.scss';
import {ConfigProvider} from "antd";

const App = () => {
    return <ConfigProvider
        theme={{
            components: {
                Button: {
                    colorPrimary: '#f9c851',
                    algorithm: true, // Enable algorithm,
                    colorWhite: '#252525'
                }
            },
            token: {
                colorPrimary: '#f9c851',
                colorPrimaryHover: '#F9C851CC',
                colorPrimaryTextHover: '#252525',
                colorPrimaryTextActive: '#252525',
                colorPrimaryText: '#252525',
                borderRadius: 3,
                colorPrimaryActive: '#252525',
                colorPrimaryBgHover: '#252525',
            },
        }}
    ><Routes/></ConfigProvider>;
};

export default App;
