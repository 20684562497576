import { Navigate } from 'react-router-dom';
import {APICore} from "../helpers/api/apiCore";

const Root = () => {
    const user = new APICore().getLoggedInUser();

    const getRootUrl = () => {
        let url: string = 'events/new';
        if (user && user.eventCount > 0) {
            url = 'events/list'
        }
        return url;
    };

    const url = getRootUrl();

    return <Navigate to={`/${url}`} />;
};

export default Root;
