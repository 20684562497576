import {all, fork, put, takeEvery, call} from 'redux-saga/effects';
import {SagaIterator} from '@redux-saga/core';
import {APICore, setAuthorization} from 'helpers/api/apiCore';
import {
    login as loginApi,
    signup as signupApi,
    confirm as confirmApi,
    forgotPassword as forgotPasswordApi,
    getProfile
} from 'helpers';
import {authApiResponseSuccess, authApiResponseError} from './actions';
import {AuthActionTypes} from './constants';
import {setCompany} from "../account/actions";
import {authApi} from "../../helpers/api/auth";
import config from "../../config";

type UserData = {
    payload: {
        username: string;
        password: string;
        fullname: string;
        email: string;
    };
    type: string;
};

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({payload: {email, password}}: UserData): SagaIterator {
    try {
        const response = yield call(loginApi, {email, password});
        const {data} = response;
        setAuthorization(data.token);
        const user = yield call(getProfile);
        api.setLoggedInUser({
            ...user.data.user,
            token: data.token,
            refreshToken: data.refreshToken
        });
        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user.data.user));
        yield put(setCompany(user.data.user.company));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
    try {
        api.setLoggedInUser(null);
        setAuthorization(null);
        window.location.href = `https://${config.COGNITO}.auth.us-east-1.amazoncognito.com/logout?client_id=${config.CLIENT_ID}&redirect_uri=${window.location.origin}/account/login&response_type=token`
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    }
}

function* signup({payload: {fullname, email, password}}: UserData): SagaIterator {
    try {
        yield call(signupApi, {fullname, email, password});
        yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, {email, password}));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* confirm({payload: {code, email, password}}: any): SagaIterator {
    try {
        const {data} = yield call(confirmApi, {code, email, password});
        setAuthorization(data.token);
        const user = yield call(getProfile);
        api.setLoggedInUser({
            ...user.data.user,
            token: data.token
        });
        yield put(authApiResponseSuccess(AuthActionTypes.CONFIRM_USER, data));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.CONFIRM_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* forgotPassword({payload: {email}}: UserData): SagaIterator {
    try {
        const response = yield call(forgotPasswordApi, {email});
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
    }
}

function* authUserEvent({payload: {code}}: any): SagaIterator {
    try {
        const response = yield call(authApi, {code});
        const {data} = response;
        setAuthorization(data.token);
        const user = yield call(getProfile);
        api.setLoggedInUser({
            ...user.data.user,
            token: data.token,
            refreshToken: data.refreshToken
        });
        yield put(authApiResponseSuccess(AuthActionTypes.AUTH_USER, user.data.user));
        yield put(setCompany(user.data.user.company));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.AUTH_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
        window.location.href = `/account/logout`;
    }
}

export function* watchLoginUser() {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup() {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchConfirm() {
    yield takeEvery(AuthActionTypes.CONFIRM_USER, confirm);
}

export function* watchForgotPassword() {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchAuthUserEvent() {
    yield takeEvery(AuthActionTypes.AUTH_USER, authUserEvent);
}

function* authSaga() {
    yield all([
            fork(watchLoginUser),
            fork(watchAuthUserEvent),
            fork(watchLogout),
            fork(watchSignup),
            fork(watchForgotPassword),
            fork(watchConfirm),
        ]
    );
}

export default authSaga;
