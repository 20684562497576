import axios from 'axios';
import config from '../../config';
import jwtDecode from "jwt-decode";
import dayjs from "dayjs";
import secureLocalStorage from "react-secure-storage";
// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.API_URL;

axios.interceptors.request.use(async (config: any) => {
    const loggedInUser = getUserFromSession();
    if (loggedInUser && config.url !== '/user/refresh') {
        const decryptToken: any = jwtDecode(loggedInUser?.token);
        if (decryptToken.exp < dayjs().unix()) {
            const baseUrl = '/user/refresh';
            try {
                const response = await axios.post(`${baseUrl}`, {refreshToken: loggedInUser.refreshToken});
                if (response.data?.token) {
                    setAuthorization(response.data.token);
                    secureLocalStorage.setItem(AUTH_SESSION_KEY, {...loggedInUser, token: response.data.token});
                    return {
                        ...config,
                        headers: {
                            ...config.headers,
                            Authorization: response.data.token
                        }
                    }
                } else {
                    return Promise.reject({response: {status: 401, message: response}})
                }
            } catch (e) {
                return Promise.reject({response: {status: 401, message: 'Failed'}})
            }
        }
    }
    return config;
}, (error) => {
    return Promise.reject(error);
})

// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;

        if (error && error.response && error.response.status === 403) {
            window.location.href = '/access-denied';
        } else if (!error.response) {
            return Promise.reject(error.message);
        } else {
            switch (error.response.status) {
                case 400:
                    message = error.response.data;
                    break;
                case 401:
                    setAuthorization(null);
                    secureLocalStorage.removeItem(AUTH_SESSION_KEY);
                    window.location.href = '/account/login'
                    message = 'Invalid credentials';
                    break;
                case 403:
                    message = 'Access Forbidden';
                    break;
                case 404:
                    message = 'Sorry! the data you are looking for could not be found';
                    break;
                case 500:
                    message = error.response.data;
                    break;
                default: {
                    message =
                        error.response && error.response.data ? error.response.data['message'] : error.message || error;
                }
            }
            return Promise.reject(message);
        }
    }
);

const AUTH_SESSION_KEY = 'ewiz_user';
const IMPERSONATION_KEY = 'ewiz_user__impersonation';

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = token;
        axios.defaults.headers.common['X-Amz-Date'] = new Date().toString();
        new APICore().getImpersonation();
    } else {
        delete axios.defaults.headers.common['Authorization'];
        delete axios.defaults.headers.common['X-AuthInfo'];
        delete axios.defaults.headers.common['X-Amz-Date'];
    }
};

const getUserFromSession = (): any => {
    const user = secureLocalStorage.getItem(AUTH_SESSION_KEY);
    return user ? user : null;
};

class APICore {
    get = (url: string, params: any) => {
        let response;
        if (params) {
            const queryString = params
                ? Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, params);
        } else {
            response = axios.get(`${url}`, params);
        }
        return response;
    };

    create = (url: string, data: any) => {
        return axios.post(url, data);
    };

    update = (url: string, data: any) => {
        return axios.put(url, data);
    };

    delete = (url: string) => {
        return axios.delete(url);
    };

    isUserAuthenticated = () => {
        const user = this.getLoggedInUser();

        return !(!user || (user && !user.token));
    };

    getIp = async () => {
        let ipValue = sessionStorage.getItem('ip');
        if (ipValue) return ipValue;

        try {
            const result = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
            const reader = await result?.body?.getReader().read();
            const ip = new TextDecoder().decode(reader?.value).trim().split('\n');
            ipValue = ip[2].split('=')[1];
        } catch (e) {
            console.error(e)
        }

        sessionStorage.setItem('ip', ipValue!);
        return ipValue;
    }

    setLoggedInUser = (session: any) => {
        if (session) {
            secureLocalStorage.setItem(AUTH_SESSION_KEY, session);
            setAuthorization(session.token);
        } else {
            secureLocalStorage.clear();
        }
    };

    getLoggedInUser = () => {
        return getUserFromSession();
    };

    setUserInSession = (modifiedUser: any) => {
        let userInfo = secureLocalStorage.getItem(AUTH_SESSION_KEY);
        if (userInfo) {
            const {token, user}: any = userInfo;
            this.setLoggedInUser({token, ...user, ...modifiedUser});
        }
    };

    getImpersonation = () => {
        let impersonation: any = secureLocalStorage.getItem(IMPERSONATION_KEY);
        if (impersonation) {
            this.setImpersonationHeaders(impersonation.email)
            return impersonation
        } else {
            return null
        }
    }

    setImpersonation = (impersonation: any | null) => {
        if (impersonation) {
            secureLocalStorage.setItem(IMPERSONATION_KEY, impersonation);
        } else {
            secureLocalStorage.removeItem(IMPERSONATION_KEY);
        }
    }

    setImpersonationHeaders = (email: string | null) => {
        if (email) {
            axios.defaults.headers.common['X-AuthInfo'] = email;
        } else {
            delete axios.defaults.headers.common['X-AuthInfo'];
        }
    }
}

/*
Check if token available in session
*/
let user = getUserFromSession();
if (user) {
    const {token} = user;
    if (token) {
        setAuthorization(token);
    }
}

export {APICore, setAuthorization};
