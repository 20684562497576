import {EventActionTypes} from "./constats";
import {errorMessageHandler} from "../../utils";

const INIT_STATE = {
    loading: false,
    loadingSession: false,
};

type EventData = {}

type State = {
    events?: EventData[];
    loading?: boolean;
    publicEvent?: any;
    loadingSession?: boolean;
}

export type EventActionType = {
    type: EventActionTypes.EVENT_CREATED
        | EventActionTypes.API_RESPONSE_ERROR
        | EventActionTypes.GET_EVENTS
        | EventActionTypes.GET_RENTALS
        | EventActionTypes.GET_RENTAL
        | EventActionTypes.CHECKOUT_RENTAL
        | EventActionTypes.GET_PICKUP_INFO
        | EventActionTypes.RENTAL_CHECKOUT
        | EventActionTypes.RESET
        | EventActionTypes.CHECKOUT
        | EventActionTypes.RESET_LOADING
        | EventActionTypes.DUPLICATE
        | EventActionTypes.RESET_PURCHASE_SESSION
        | EventActionTypes.CHECK_DISCOUNT
        | EventActionTypes.REMOVE_DISCOUNT
        | EventActionTypes.UPDATE_CART
        | EventActionTypes.GET_CART
        | EventActionTypes.RESET_RSVP
        | EventActionTypes.CREATE_RSVP
        | EventActionTypes.CREATE_EVENT
        | EventActionTypes.EVENT_CHECKIN
        | EventActionTypes.UPDATE_ADMIN_CART
        | EventActionTypes.SEND_PAYMENT_ADMIN
        | EventActionTypes.CHECKOUT_ADMIN_CART
        | EventActionTypes.EDIT_EVENT
        | EventActionTypes.GET_EVENT
        | EventActionTypes.GET_PUBLIC_EVENT
        | EventActionTypes.RSVP_SEARCH
        | EventActionTypes.FETCH_CUSTOMER_DATA
        | EventActionTypes.RESET_SEARCH
        | EventActionTypes.GET_TIMEBASED_RSVP
        | EventActionTypes.ADD_TO_WAITLIST
        | EventActionTypes.RESET_WAITLIST
        | EventActionTypes.API_RESPONSE_SUCCESS;
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    } | any
}
export default function Events(state: State = INIT_STATE, action: EventActionType) {
    switch (action.type) {
        case EventActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case EventActionTypes.EVENT_CREATED:
                    return {
                        ...state,
                        id: action.payload.data.eventId,
                        loading: false,
                    }
                case EventActionTypes.GET_RENTALS:
                    return {
                        ...state,
                        loading: false,
                        rentals: action.payload.data.rentals,
                    }
                case EventActionTypes.GET_PICKUP_INFO:
                    return {
                        ...state,
                        loading: false,
                        pickup: action.payload.data.pickup,
                    }
                case EventActionTypes.GET_EVENTS:
                    return {
                        ...state,
                        events: action.payload.data.events,
                        loading: false,
                    }
                case EventActionTypes.GET_RENTAL:
                    return {
                        ...state,
                        rental: action.payload.data.rental,
                        loading: false,
                    }
                case EventActionTypes.GET_EVENT:
                    return {
                        ...state,
                        event: {
                            ...action.payload.data.event,
                        },
                        loading: false,
                    }
                case EventActionTypes.CHECKOUT:
                    return {
                        ...state,
                        redirect: action.payload.data.redirect,
                        sessionId: action.payload.data.sessionId,
                    }
                case EventActionTypes.RENTAL_CHECKOUT:
                    return {
                        ...state,
                        redirectRental: action.payload.data.redirect,
                        redirectSessionId: action.payload.data.sessionId,
                        loading: false,
                    }
                case EventActionTypes.GET_CART:
                    return {
                        ...state,
                        rsvpResponse: 'Success',
                        loadingSession: false,
                    }
                case EventActionTypes.GET_PUBLIC_EVENT:
                    return {
                        ...state,
                        publicEvent: {
                            ...action.payload.data.event,
                        },
                        loading: false,
                    }
                case EventActionTypes.EVENT_CHECKIN:
                    return {
                        ...state,
                        checkInData: action.payload.data,
                        loading: false,
                    }
                case EventActionTypes.UPDATE_CART:
                    return {
                        ...state,
                        publicEvent: {
                            ...state.publicEvent,
                            session: action.payload.data
                        },
                        loading: false,
                        error: false,
                    }
                case EventActionTypes.UPDATE_ADMIN_CART:
                    return {
                        ...state,
                        publicEvent: {
                            session: action.payload.data
                        },
                        loading: false,
                    }
                case EventActionTypes.SEND_PAYMENT_ADMIN:
                    return {
                        ...state,
                        linkSent: true,
                        loading: false,
                        publicEvent: null
                    }
                case EventActionTypes.CHECKOUT_ADMIN_CART:
                    return {
                        ...state,
                        rsvpResponse: 'Success',
                        loadingSession: false,
                        loading: false,
                    }
                case EventActionTypes.EDIT_EVENT:
                    return {
                        ...state,
                        event: {
                            ...action.payload.data
                        },
                        loading: false
                    }
                case EventActionTypes.CREATE_RSVP:
                    return {
                        ...state,
                        rsvpLoading: false,
                        rsvpResponse: 'Success',
                        errorCreate: null,
                    }
                case EventActionTypes.RSVP_SEARCH:
                    return {
                        ...state,
                        loadingRsvp: false,
                        customers: action.payload.data.customers,
                    }
                case EventActionTypes.FETCH_CUSTOMER_DATA:
                    return {
                        ...state,
                        loadingRsvp: false,
                        rsvpsForCustomer: action.payload.data.data,
                    }
                case EventActionTypes.CHECK_DISCOUNT:
                    return {
                        ...state,
                        loadingDiscount: false,
                        publicEvent: {
                            ...state.publicEvent,
                            session: action.payload.data
                        },
                    }
                case EventActionTypes.CHECKOUT_RENTAL:
                    return {
                        ...state,
                        loading: false,
                        rental: action.payload.data.rental
                    }
                case EventActionTypes.GET_TIMEBASED_RSVP:
                    return {
                        ...state,
                        loadingTimeBased: false,
                        errorTimeBased: null,
                        timeBasedRsvps: action.payload.data.timeBasedRsvps,
                        waitListData: action.payload.data.waitListData,
                    }
                case EventActionTypes.ADD_TO_WAITLIST:
                    return {
                        ...state,
                        addedToWaitList: true,
                        loadingWaitlist: false,
                        error: null,
                        publicEvent: {
                            ...state.publicEvent,
                            session: action.payload.data.qty
                        },
                    }
                default:
                    return {...state, loading: false}
            }
        case EventActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case EventActionTypes.GET_CART:
                    return {
                        ...state,
                        error: errorMessageHandler(action.payload.error),
                        loadingSession: false,
                    }
                case EventActionTypes.GET_PUBLIC_EVENT:
                    return {
                        ...state,
                        loading: false,
                        error: action.payload.error,
                        notFound: true,
                    }
                case EventActionTypes.CREATE_RSVP:
                    return {
                        ...state,
                        rsvpLoading: false,
                        errorCreate: Object.values(action.payload.error as {})[0]
                    }
                case EventActionTypes.CHECK_DISCOUNT:
                    return {
                        ...state,
                        loadingDiscount: false,
                        errorDiscount: Object.values(action.payload.error as {})[0]
                    }
                case EventActionTypes.RSVP_SEARCH:
                    return {
                        ...state,
                        loadingRsvp: false,
                        error: errorMessageHandler(action.payload.error)
                    }
                case EventActionTypes.GET_TIMEBASED_RSVP:
                    return {
                        ...state,
                        loadingTimeBased: false,
                        errorTimeBased: errorMessageHandler(action.payload.error),
                    }
                case EventActionTypes.FETCH_CUSTOMER_DATA:
                    return {
                        ...state,
                        loadingRsvp: false,
                        error: errorMessageHandler(action.payload.error)
                    }
                case EventActionTypes.ADD_TO_WAITLIST:
                    return {
                        ...state,
                        loadingWaitlist: false,
                        error: errorMessageHandler(action.payload.error)
                    }
                default:
                    return {
                        ...state,
                        loading: false,
                        loadingSession: false,
                        error: errorMessageHandler(action.payload.error)
                    }
            }
        case EventActionTypes.EVENT_CREATED:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case EventActionTypes.DUPLICATE:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case EventActionTypes.GET_RENTALS:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case EventActionTypes.CHECK_DISCOUNT:
            return {
                ...state,
                loadingDiscount: true,
                errorDiscount: null,
                error: null,
            }
        case EventActionTypes.GET_EVENTS:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case EventActionTypes.GET_CART:
            return {
                ...state,
                loadingSession: true,
                error: null,
            }
        case EventActionTypes.CREATE_EVENT:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case EventActionTypes.GET_EVENT:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case EventActionTypes.GET_PUBLIC_EVENT:
            return {
                ...state,
                loading: true,
                error: null,
                notFound: false,
            }
        case EventActionTypes.EDIT_EVENT:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case EventActionTypes.RESET:
            return {
                ...state,
                loading: false,
                id: null,
                event: null,
                events: [],
                error: null,
                publicEvent: null,
                rsvpLoading: false,
                errorCreate: false,
            }
        case EventActionTypes.RESET_LOADING:
            return {
                ...state,
                loading: false,
                error: null,
            }
        case EventActionTypes.RESET_PURCHASE_SESSION:
            return {
                ...state,
                loading: false,
                publicEvent: {
                    ...state.publicEvent,
                    session: null
                }
            }
        case EventActionTypes.RESET_RSVP:
            return {
                ...state,
                loading: false,
                email: false,
                errorCreate: null,
                redirect: null,
                error: null,
            }
        case EventActionTypes.CREATE_RSVP:
            return {
                ...state,
                rsvpLoading: true,
                error: null,
            }
        case EventActionTypes.CHECKOUT:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case EventActionTypes.SEND_PAYMENT_ADMIN:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case EventActionTypes.UPDATE_ADMIN_CART:
            return {
                ...state,
                error: null,
                linkSent: null,
            }
        case EventActionTypes.CHECKOUT_ADMIN_CART:
            return {
                ...state,
                error: null,
                loadingSession: true,
                loading: true,
            }
        case EventActionTypes.EVENT_CHECKIN:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case EventActionTypes.GET_PICKUP_INFO:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case EventActionTypes.RENTAL_CHECKOUT:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case EventActionTypes.GET_RENTAL:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case EventActionTypes.CHECKOUT_RENTAL:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case EventActionTypes.RSVP_SEARCH:
            return {
                ...state,
                loadingRsvp: true,
                error: null,
            }
        case EventActionTypes.RESET_SEARCH:
            return {
                ...state,
                loadingRsvp: false,
                error: null,
                customers: null,
            }
        case EventActionTypes.FETCH_CUSTOMER_DATA:
            return {
                ...state,
                loadingRsvp: true,
                error: null,
                rsvpsForCustomer: null,
            }
        case EventActionTypes.GET_TIMEBASED_RSVP:
            return {
                ...state,
                loadingTimeBased: true,
                errorTimeBased: null,
            }
        case EventActionTypes.ADD_TO_WAITLIST:
            return {
                ...state,
                loadingWaitlist: false,
                error: null,
            }
        case EventActionTypes.RESET_WAITLIST:
            return {
                ...state,
                addedToWaitList: false,
                error: null,
            }
        default:
            return {...state};
    }
}
