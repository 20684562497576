import {ReservationActionType} from "./reducers";
import {ReservationActionTypes} from "./constants";

export const reservationApiResponseSuccess = (actionType: string, data: {}): ReservationActionType => ({
    type: ReservationActionTypes.API_RESPONSE_SUCCESS,
    payload: {actionType, data},
});

export const reservationApiResponseError = (actionType: string, error: string): ReservationActionType => ({
    type: ReservationActionTypes.API_RESPONSE_ERROR,
    payload: {actionType, error},
});

export const getReservation = (id: any): ReservationActionType => ({
    type: ReservationActionTypes.GET_RESERVATION,
    payload: {id}
})

export const setReservationId = (id: any): ReservationActionType => ({
    type: ReservationActionTypes.SET_RESERVATION_ID,
    payload: id
})

export const confirmAuthorizationCode = (code: any): ReservationActionType => ({
    type: ReservationActionTypes.CONFIRM_AUTH_CODE,
    payload: code
})

export const manageReservationEvent = (data: any, action: any, code: any): ReservationActionType => ({
    type: ReservationActionTypes.MANAGE_RESERVATION,
    payload: {...data, action, code}
})

export const resetManageAction = (): ReservationActionType => ({
    type: ReservationActionTypes.RESET_MANAGE,
    payload: null
})

export const acceptTransfer = (id: any): ReservationActionType => ({
    type: ReservationActionTypes.ACCEPT_TRANSFER,
    payload: id
})
