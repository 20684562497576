import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {ReservationActionTypes} from "./constants";
import {SagaIterator} from "@redux-saga/core";
import {reservationApiResponseError, reservationApiResponseSuccess} from "./actions";
import {
    acceptTransferApi,
    confirmReservationCodeApi,
    getReservationApi,
    manageReservationApi,
    sendReservationCodeApi
} from "../../helpers";
import {RESERVATION_ID, RESERVATION_KEY} from "./reducers";

function* getReservationEvent(payload: { payload: any, type: ReservationActionTypes }): SagaIterator {
    try {
        const response = yield call(getReservationApi, payload.payload);
        yield put(reservationApiResponseSuccess(ReservationActionTypes.GET_RESERVATION, response.data));
    } catch (error: any) {
        sessionStorage.removeItem(RESERVATION_KEY);
        sessionStorage.removeItem(RESERVATION_ID);
        yield put(reservationApiResponseError(ReservationActionTypes.GET_RESERVATION, error));
    }
}

function* setReservationIdEvent(payload: { payload: any, type: ReservationActionTypes }): SagaIterator {
    try {
        sessionStorage.removeItem(RESERVATION_KEY);
        const response = yield call(sendReservationCodeApi, payload.payload);
        sessionStorage.setItem(RESERVATION_ID, payload.payload);
        yield put(reservationApiResponseSuccess(ReservationActionTypes.SET_RESERVATION_ID, response.data));
    } catch (error: any) {
        yield put(reservationApiResponseError(ReservationActionTypes.SET_RESERVATION_ID, error));
    }
}

function* manageReservationAction(payload: { payload: any, type: ReservationActionTypes }): SagaIterator {
    try {
        const response = yield call(manageReservationApi, payload.payload);
        yield put(reservationApiResponseSuccess(ReservationActionTypes.MANAGE_RESERVATION, response.data));
    } catch (error: any) {
        yield put(reservationApiResponseError(ReservationActionTypes.MANAGE_RESERVATION, error));
    }
}

function* acceptTransferEvent(payload: { payload: any, type: ReservationActionTypes }): SagaIterator {
    try {
        const response = yield call(acceptTransferApi, payload.payload);
        yield put(reservationApiResponseSuccess(ReservationActionTypes.ACCEPT_TRANSFER, response.data));
        sessionStorage.setItem(RESERVATION_ID, payload.payload);
        sessionStorage.setItem(RESERVATION_KEY, response.data.code);
        window.location.href = `/reservations/${payload.payload}`;
    } catch (error: any) {
        yield put(reservationApiResponseError(ReservationActionTypes.ACCEPT_TRANSFER, error));
    }
}

function* setConfirmAuthCodeEvent(payload: { payload: any, type: ReservationActionTypes }): SagaIterator {
    try {
        const response = yield call(confirmReservationCodeApi, payload.payload);
        yield put(reservationApiResponseSuccess(ReservationActionTypes.CONFIRM_AUTH_CODE, response.data));
    } catch (error: any) {
        sessionStorage.removeItem(RESERVATION_KEY);
        sessionStorage.removeItem(RESERVATION_ID);
        yield put(reservationApiResponseError(ReservationActionTypes.CONFIRM_AUTH_CODE, error));
    }
}

export function* watchGetReservationEvent() {
    yield takeEvery(ReservationActionTypes.GET_RESERVATION, getReservationEvent)
}

export function* watchSetReservationIdEvent() {
    yield takeEvery(ReservationActionTypes.SET_RESERVATION_ID, setReservationIdEvent)
}

export function* watchConfirmCodeEvent() {
    yield takeEvery(ReservationActionTypes.CONFIRM_AUTH_CODE, setConfirmAuthCodeEvent)
}

export function* watchManageReservationEvent() {
    yield takeEvery(ReservationActionTypes.MANAGE_RESERVATION, manageReservationAction)
}

export function* watchAcceptTransferEvent() {
    yield takeEvery(ReservationActionTypes.ACCEPT_TRANSFER, acceptTransferEvent)
}

function* reservationSaga() {
    yield all([
        fork(watchGetReservationEvent),
        fork(watchSetReservationIdEvent),
        fork(watchConfirmCodeEvent),
        fork(watchManageReservationEvent),
        fork(watchAcceptTransferEvent),
    ]);
}

export default reservationSaga;
