export enum EventActionTypes {
    API_RESPONSE_SUCCESS = '@@events/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@events/API_RESPONSE_ERROR',
    RESET = '@@events/RESET',
    RESET_RSVP = '@@events/RESET_RSVP',
    CREATE_RSVP = '@@events/CREATE_RSVP',
    CHECKOUT = '@@events/CHECKOUT',
    GET_CART = '@@events/GET_CART',
    UPDATE_CART = '@@events/UPDATE_CART',

    UPDATE_ADMIN_CART = '@@events/UPDATE_ADMIN_CART',
    SEND_PAYMENT_ADMIN = '@@events/SEND_PAYMENT_ADMIN',
    CHECKOUT_ADMIN_CART = '@@events/CHECKOUT_ADMIN_CART',

    RESET_LOADING = '@@events/RESET_LOADING',
    RESET_PURCHASE_SESSION = '@@events/RESET_PURCHASE_SESSION',

    CREATE_EVENT = '@@events/CREATE_EVENT',
    EDIT_EVENT = '@@events/EDIT_EVENT',
    DUPLICATE = '@@events/DUPLICATE',
    EVENT_CREATED = '@@events/EVENT_CREATED',
    GET_EVENTS = '@@events/GET_EVENTS',
    GET_EVENT = '@@events/GET_EVENT',
    GET_PUBLIC_EVENT = '@@events/GET_PUBLIC_EVENT',
    EVENT_CHECKIN = '@@events/EVENT_CHECKIN',

    CHECK_DISCOUNT = '@@events/CHECK_DISCOUNT',
    REMOVE_DISCOUNT = '@@events/REMOVE_DISCOUNT',

    GET_RENTALS = '@@events/GET_RENTALS',
    RENTAL_CHECKOUT = '@@events/RENTAL_CHECKOUT',
    GET_PICKUP_INFO = '@@events/GET_PICKUP_INFO',

    GET_RENTAL = '@@events/GET_RENTAL',
    CHECKOUT_RENTAL = '@@events/CHECKOUT_RENTAL',

    RSVP_SEARCH = '@@events/RSVP_SEARCH',
    FETCH_CUSTOMER_DATA = '@@events/FETCH_CUSTOMER_DATA',
    RESET_SEARCH = '@@events/RESET_SEARCH',
    GET_TIMEBASED_RSVP = '@@events/GET_TIMEBASED_RSVP',

    ADD_TO_WAITLIST = '@@events/ADD_TO_WAITLIST',
    RESET_WAITLIST = '@@events/RESET_WAITLIST',
}
