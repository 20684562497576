export enum ReservationActionTypes {
    API_RESPONSE_SUCCESS = '@@reservations/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@reservations/API_RESPONSE_ERROR',

    GET_RESERVATION = '@@reservations/GET_RESERVATION',
    SET_RESERVATION_ID = '@@reservations/SET_RESERVATION_ID',
    CONFIRM_AUTH_CODE = '@@reservations/CONFIRM_AUTH_CODE',

    MANAGE_RESERVATION = '@@reservations/MANAGE_RESERVATION',
    RESET_MANAGE = '@@reservations/RESET_MANAGE',

    ACCEPT_TRANSFER = '@@reservations/ACCEPT_TRANSFER',
}
