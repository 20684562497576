import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import eventsSaga from './events/saga';
import accountSaga from './account/saga';
import adminSaga from "./admin/saga";
import dashboardSaga from "./dashboard/saga";
import reservationSaga from "./reservation/saga";

export default function* rootSaga() {
    yield all([authSaga(), layoutSaga(), eventsSaga(), accountSaga(), adminSaga(), dashboardSaga(), reservationSaga()]);
}
