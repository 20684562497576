import {DashboardActionTypes} from "./constats";
import {errorMessageHandler} from "../../utils";

const INIT_STATE = {
    loading: false,
    loadingTopProducts: false
};

type DashboardData = {}

type State = {
    events?: DashboardData[];
    loading?: boolean;
    publicEvent?: any
}

export type DashboardActionType = {
    type: DashboardActionTypes.API_RESPONSE_ERROR
        | DashboardActionTypes.RESET
        | DashboardActionTypes.GET_ALL
        | DashboardActionTypes.GET_RSVPS
        | DashboardActionTypes.GET_REVENUES
        | DashboardActionTypes.GET_CHECK_INS
        | DashboardActionTypes.GET_STATS
        | DashboardActionTypes.GET_REVENUES_BY_EVENT
        | DashboardActionTypes.GET_TOP_PRODUCTS
        | DashboardActionTypes.GET_REVENUE_CHART
        | DashboardActionTypes.GET_RSVP_STATS
        | DashboardActionTypes.GET_TICKETS_SOLD
        | DashboardActionTypes.API_RESPONSE_SUCCESS;
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    } | any
}
export default function Dashboard(state: State = INIT_STATE, action: DashboardActionType) {
    switch (action.type) {
        case DashboardActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case DashboardActionTypes.GET_RSVPS: {
                    return {
                        ...state,
                        loadingRsvps: false,
                        rsvpData: action.payload.data
                    }
                }
                case DashboardActionTypes.GET_CHECK_INS: {
                    return {
                        ...state,
                        loadingCheckIns: false,
                        checkInData: action.payload.data
                    }
                }
                case DashboardActionTypes.GET_TICKETS_SOLD: {
                    return {
                        ...state,
                        loadingTicketsSold: false,
                        ticketSoldData: action.payload.data
                    }
                }
                case DashboardActionTypes.GET_REVENUES: {
                    return {
                        ...state,
                        loadingRevenues: false,
                        revenueData: action.payload.data
                    }
                }
                case DashboardActionTypes.GET_REFUNDS: {
                    return {
                        ...state,
                        loadingRefunds: false,
                        refundData: action.payload.data
                    }
                }
                case DashboardActionTypes.GET_REVENUES_BY_EVENT: {
                    return {
                        ...state,
                        loadingRevenuesByEvent: false,
                        revenueByEventData: action.payload.data
                    }
                }
                case DashboardActionTypes.GET_REVENUE_CHART: {
                    return {
                        ...state,
                        loadingRevenueChart: false,
                        revenueChartData: action.payload.data
                    }
                }
                case DashboardActionTypes.GET_TOP_PRODUCTS: {
                    return {
                        ...state,
                        loadingTopProducts: false,
                        topProductData: action.payload.data
                    }
                }
                case DashboardActionTypes.GET_GROWTH_DATA: {
                    return {
                        ...state,
                        loadingGrowth: false,
                        growthData: action.payload.data
                    }
                }
                case DashboardActionTypes.GET_DISCOUNTS_APPLIED: {
                    return {
                        ...state,
                        loadingDiscountsApplied: false,
                        discountsAppliedData: action.payload.data
                    }
                }
                case DashboardActionTypes.GET_EWIZ_FEES: {
                    return {
                        ...state,
                        loadingEwizFees: false,
                        ewizFeeData: action.payload.data
                    }
                }
                default:
                    return {...state, loading: false}
            }
        case DashboardActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case DashboardActionTypes.GET_RSVPS: {
                    return {
                        ...state,
                        loadingRsvps: false
                    }
                }
                case DashboardActionTypes.GET_TICKETS_SOLD: {
                    return {
                        ...state,
                        loadingTicketsSold: false
                    }
                }
                case DashboardActionTypes.GET_REVENUES: {
                    return {
                        ...state,
                        loadingRevenues: false
                    }
                }
                case DashboardActionTypes.GET_REFUNDS: {
                    return {
                        ...state,
                        loadingRefunds: false
                    }
                }
                case DashboardActionTypes.GET_REVENUES_BY_EVENT: {
                    return {
                        ...state,
                        loadingRevenuesByEvent: false
                    }
                }
                case DashboardActionTypes.GET_REVENUE_CHART: {
                    return {
                        ...state,
                        loadingRevenueChart: false
                    }
                }
                case DashboardActionTypes.GET_GROWTH_DATA: {
                    return {
                        ...state,
                        loadingGrowth: false,
                    }
                }
                case DashboardActionTypes.GET_TOP_PRODUCTS: {
                    return {
                        ...state,
                        loadingTopProducts: false
                    }
                }
                case DashboardActionTypes.GET_DISCOUNTS_APPLIED: {
                    return {
                        ...state,
                        loadingDiscountsApplied: false
                    }
                }
                case DashboardActionTypes.GET_EWIZ_FEES: {
                    return {
                        ...state,
                        loadingEwizFees: false
                    }
                }
                case DashboardActionTypes.GET_ALL:
                    return {
                        ...state,
                        error: Object.values(action.payload.error as {})[0],
                        loadingRsvps: false,
                        loadingTicketsSold: false,
                        loadingTopProducts: false,
                        loadingRevenues: false,
                        loadingRevenueChart: false,
                        loadingRevenuesByEvent: false,
                        loadingGrowth: false,
                        loadingDiscountsApplied: false,
                        loadingEwizFees: false,
                        loadingCheckIns: false,
                        loading: false,
                    }
                case DashboardActionTypes.GET_STATS:
                    return {
                        ...state,
                        error: Object.values(action.payload.error as {})[0],
                        loadingRsvps: false,
                        loadingTicketsSold: false,
                        loadingTopProducts: false,
                        loadingRevenues: false,
                        loadingGrowth: false,
                        loadingEwizFees: false,
                        loadingDiscountsApplied: false,
                        loadingCheckIns: false,
                        loading: false,
                    }
                case DashboardActionTypes.GET_RSVP_STATS:
                    return {
                        ...state,
                        error: errorMessageHandler(action.payload.error),
                        loadingRsvps: false,
                        loading: false,
                        loadingCheckIns: false
                    }
                default:
                    return {...state, loading: false}
            }
        case DashboardActionTypes.GET_ALL: {
            return {
                ...state,
                loadingRsvps: true,
                loadingTicketsSold: true,
                loadingRevenues: true,
                loadingRevenuesByEvent: true,
                loadingRevenueChart: true,
                loadingTopProducts: true,
                loadingGrowth: true,
                loadingDiscountsApplied: true,
                loadingEwizFees: true,
                loadingCheckIns: true,
                loadingRefunds: true,
            }
        }
        case DashboardActionTypes.GET_STATS: {
            return {
                ...state,
                loadingRsvps: true,
                loadingTicketsSold: true,
                loadingRevenues: true,
                loadingTopProducts: true,
                loadingGrowth: true,
                loadingEwizFees: true,
                loadingDiscountsApplied: true,
                loadingCheckIns: true,
                loadingRefunds: true
            }
        }
        case DashboardActionTypes.GET_RSVP_STATS: {
            return {
                ...state,
                loadingRsvps: true,
                loadingCheckIns: true,
            }
        }
        case DashboardActionTypes.RESET:
            return {
                ...state,
                loading: false,
                id: null,
                event: null,
                events: [],
                error: null,
                publicEvent: null,
            }
        default:
            return {...state};
    }
}
