import {useEffect, useRef} from "react";

export const getMultipleTreeOfKeys = (itemKey: any, multiples: any) => {
    let keySet = [itemKey];

    multiples.forEach((multiple: any) => {
        const keysForParent = multiples.filter((m1: any) => keySet.includes(m1.parent));

        keySet = [...keySet, ...keysForParent.map((k1: any) => k1.key)];
    });

    return keySet.filter((item: any, pos: any) => (keySet.indexOf(item) === pos));
}

export const getMultipleTreeOfKeysWithParentKey = (parentKey: any, itemKey: any, multiples: any) => {
    let keySet = [itemKey, parentKey];

    multiples?.forEach((multiple: any) => {
        if (keySet.includes(multiple.key) || keySet.includes(multiple.parent))
            keySet = [...keySet, multiple.parent];
    });

    return keySet?.filter((item: any, pos: any) => (keySet.indexOf(item) === pos));
}

export const getMultipleTicketName = (multiple: any, multiples: any) => {
    const keys = getMultipleTreeOfKeysWithParentKey(multiple.parent, multiple.key, multiples);
    const items = multiples.filter((multi: any) => keys.indexOf(multi.key) > -1)
    return items.map((item: any) => item.name).join(' - ');
}

export const useDebounce = (callback: any, delay: any) => {
    const timeoutRef = useRef<any>(null);

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const debouncedCallback = (...args: any[]) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            callback(...args);
        }, delay);
    };

    return debouncedCallback;
};

export const findAllParentsAndChildren = (key: any, parent: any, multiples: any) => {
    const arrayOfKeysThatFitAsSetOfChildren: any = [];
    const arrayOfKeysThatFitAsSetOfParents: any = [];

    multiples.forEach((mtp: any) => {
        if (mtp.parent === key) {
            arrayOfKeysThatFitAsSetOfChildren.push(mtp.key);
            multiples.filter((m2: any) => m2.parent === mtp.key).forEach((key: any) => arrayOfKeysThatFitAsSetOfChildren.push(key.key))
        }
        if (mtp.parent === parent) {
            arrayOfKeysThatFitAsSetOfParents.push(mtp.parent);
            multiples.filter((m2: any) => m2.key === mtp.parent).forEach((filtered: any) => arrayOfKeysThatFitAsSetOfParents.push(filtered.parent))
        }
    });

    const fullSetOfChildren = multiples.filter((mtp: any) => arrayOfKeysThatFitAsSetOfChildren.indexOf(mtp.key) > -1);
    const fullSetOfParents = multiples.filter((mtp: any) => arrayOfKeysThatFitAsSetOfParents.indexOf(mtp.key) > -1);

    return [fullSetOfChildren, fullSetOfParents]
}
