import {AdminActionTypes} from "./constants";
import {AdminActionType} from "./reducers";

export const adminApiResponseSuccess = (actionType: string, data: {}): AdminActionType => ({
    type: AdminActionTypes.API_RESPONSE_SUCCESS,
    payload: {actionType, data},
});

export const adminApiResponseError = (actionType: string, error: string): AdminActionType => ({
    type: AdminActionTypes.API_RESPONSE_ERROR,
    payload: {actionType, error},
});

export const getUsers = (): AdminActionType => ({
    type: AdminActionTypes.GET_USERS,
    payload: null
})

export const reset = (): AdminActionType => ({
    type: AdminActionTypes.RESET,
    payload: null
})

export const resetImpersonation = (): AdminActionType => ({
    type: AdminActionTypes.RESET_IMPERSONATION,
    payload: null
})

export const impersonate = (data: string | boolean): AdminActionType => ({
    type: AdminActionTypes.IMPERSONATE,
    payload: data
})

export const getStripeAccounts = (): AdminActionType => ({
    type: AdminActionTypes.GET_STRIPE_ACCOUNTS,
    payload: null
})

export const deleteAccount = (id: string): AdminActionType => ({
    type: AdminActionTypes.DELETE_STRIPE_ACCOUNT,
    payload: id
})

export const getRsvps = (dates: any): AdminActionType => ({
    type: AdminActionTypes.GET_RSVPS,
    payload: dates
})

export const retryTicket = (data: any): AdminActionType => ({
    type: AdminActionTypes.RETRY_TICKET,
    payload: data
})

export const cancelTicket = (data: any): AdminActionType => ({
    type: AdminActionTypes.CANCEL_TICKET,
    payload: data
})

export const resetCancelTicket = (): AdminActionType => ({
    type: AdminActionTypes.RESET_CANCELLATION,
    payload: null
})

export const updateRsvp = (id: string, updateData: any): AdminActionType => ({
    type: AdminActionTypes.UPDATE_RSVP,
    payload: {id, updateData}
})

export const updateUser = (data: any): AdminActionType => ({
    type: AdminActionTypes.UPDATE_USER,
    payload: data
})

export const getRentals = (): AdminActionType => ({
    type: AdminActionTypes.GET_RENTALS,
    payload: null
})

export const updateRentals = (data: any): AdminActionType => ({
    type: AdminActionTypes.UPDATE_RENTALS,
    payload: data
})

export const refund = (data: any): AdminActionType => ({
    type: AdminActionTypes.REFUND,
    payload: data
})
