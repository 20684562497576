export enum DashboardActionTypes {
    API_RESPONSE_SUCCESS = '@@dashboard/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@dashboard/API_RESPONSE_ERROR',
    RESET = '@@dashboard/RESET',

    GET_ALL = '@@dashboard/GET_ALL',
    GET_RSVPS = '@@dashboard/GET_RSVPS',
    GET_CHECK_INS = '@@dashboard/GET_CHECK_INS',
    GET_TICKETS_SOLD = '@@dashboard/GET_TICKETS_SOLD',
    GET_REVENUES = '@@dashboard/GET_REVENUES',
    GET_REVENUES_BY_EVENT = '@@dashboard/GET_REVENUES_BY_EVENT',
    GET_REVENUE_CHART = '@@dashboard/GET_REVENUE_CHART',
    GET_TOP_PRODUCTS = '@@dashboard/GET_TOP_PRODUCTS',
    GET_GROWTH_DATA = '@@dashboard/GET_GROWTH_DATA',
    GET_STATS = '@@dashboard/GET_STATS',
    GET_RSVP_STATS = '@@dashboard/GET_RSVP_STATS',
    GET_EWIZ_FEES = '@@dashboard/GET_EWIZ_FEES',
    GET_DISCOUNTS_APPLIED = '@@dashboard/GET_DISCOUNTS_APPLIED',
    GET_REFUNDS = '@@dashboard/GET_REFUNDS',
}
