export type MenuItemType = {
    key: string;
    label: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    children?: MenuItemType[];
    preProduction?: boolean;
};

const MENU_ITEMS: MenuItemType[] = [
    { key: 'navigation', label: 'Navigation', isTitle: true },
    {
        key: 'dashboard',
        label: 'Dashboard',
        isTitle: false,
        icon: 'uil-chart',
        url: '/dashboard/events',
        preProduction: true,
    },
    {
        key: 'events',
        label: 'Events',
        isTitle: false,
        icon: 'uil-briefcase',
        children: [
            {
                key: 'event-list',
                label: 'List',
                url: '/events/list',
                parentKey: 'events',
            },
            {
                key: 'event-create-event',
                label: 'Create Event',
                url: '/events/new',
                parentKey: 'events',
            },
        ],
    },
];

export { MENU_ITEMS };
