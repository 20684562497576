export enum AdminActionTypes {
    API_RESPONSE_SUCCESS = '@@admin/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@admin/API_RESPONSE_ERROR',
    RESET = '@@admin/RESET',
    RESET_IMPERSONATION = '@@admin/RESET_IMPERSONATION',
    UPDATE_USER = '@@admin/UPDATE_USER',

    GET_USERS = '@@admin/GET_USERS',

    RETRY_TICKET = '@@admin/RETRY_TICKET',
    CANCEL_TICKET = '@@admin/CANCEL_TICKET',
    RESET_CANCELLATION = '@@admin/RESET_CANCELLATION',

    GET_RSVPS = '@@admin/GET_RSVPS',
    UPDATE_RSVP = '@@admin/UPDATE_RSVP',


    GET_STRIPE_ACCOUNTS = '@@admin/GET_STRIPE_ACCOUNTS',
    DELETE_STRIPE_ACCOUNT = '@@admin/DELETE_STRIPE_ACCOUNT',

    IMPERSONATE = '@@admin/IMPERSONATE',

    GET_RENTALS = '@@admin/GET_RENTALS',
    UPDATE_RENTALS = '@@admin/UPDATE_RENTALS',

    REFUND = '@@admin/REFUND',
}
