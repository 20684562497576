import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {DashboardActionTypes} from "./constats";
import {SagaIterator} from "@redux-saga/core";
import {dashboardApiResponseError, dashboardApiResponseSuccess} from "./actions";
import {getAnalyticDataApi} from "../../helpers";


function* getRsvpsAnalyticsEvent(payload: { payload: any, type: DashboardActionTypes }): SagaIterator {
    try {
        const response = yield call(getAnalyticDataApi, payload.payload, 'rsvps');
        yield put(dashboardApiResponseSuccess(DashboardActionTypes.GET_RSVPS, response.data));
    } catch (error: any) {
        yield put(dashboardApiResponseError(payload.type, error));
    }
}

function* getCheckInsEvent(payload: { payload: any, type: DashboardActionTypes }): SagaIterator {
    try {
        const response = yield call(getAnalyticDataApi, payload.payload, 'checkIns');
        yield put(dashboardApiResponseSuccess(DashboardActionTypes.GET_CHECK_INS, response.data));
    } catch (error: any) {
        yield put(dashboardApiResponseError(payload.type, error));
    }
}

function* getTicketsSoldAnalytics(payload: { payload: any, type: DashboardActionTypes }): SagaIterator {
    try {
        const response = yield call(getAnalyticDataApi, payload.payload, 'ticketsSold');
        yield put(dashboardApiResponseSuccess(DashboardActionTypes.GET_TICKETS_SOLD, response.data));
    } catch (error: any) {
        yield put(dashboardApiResponseError(payload.type, error));
    }
}

function* getRevenueAnalyticsEvent(payload: { payload: any, type: DashboardActionTypes }): SagaIterator {
    try {
        const response = yield call(getAnalyticDataApi, payload.payload, 'revenues');
        yield put(dashboardApiResponseSuccess(DashboardActionTypes.GET_REVENUES, response.data));
    } catch (error: any) {
        yield put(dashboardApiResponseError(payload.type, error));
    }
}

function* getRefundAnalyticsEvent(payload: { payload: any, type: DashboardActionTypes }): SagaIterator {
    try {
        const response = yield call(getAnalyticDataApi, payload.payload, 'refunds');
        yield put(dashboardApiResponseSuccess(DashboardActionTypes.GET_REFUNDS, response.data));
    } catch (error: any) {
        yield put(dashboardApiResponseError(payload.type, error));
    }
}

function* getRevenueAnalyticsByEventEvent(payload: { payload: any, type: DashboardActionTypes }): SagaIterator {
    try {
        const response = yield call(getAnalyticDataApi, payload.payload, 'eventRevenues');
        yield put(dashboardApiResponseSuccess(DashboardActionTypes.GET_REVENUES_BY_EVENT, response.data));
    } catch (error: any) {
        yield put(dashboardApiResponseError(payload.type, error));
    }
}

function* getRevenueChartAnalyticsEvent(payload: { payload: any, type: DashboardActionTypes }): SagaIterator {
    try {
        const response = yield call(getAnalyticDataApi, payload.payload, 'revenueChart');
        yield put(dashboardApiResponseSuccess(DashboardActionTypes.GET_REVENUE_CHART, response.data));
    } catch (error: any) {
        yield put(dashboardApiResponseError(payload.type, error));
    }
}

function* getTopProductsAnalyticsEvent(payload: { payload: any, type: DashboardActionTypes }): SagaIterator {
    try {
        const response = yield call(getAnalyticDataApi, payload.payload, 'topProducts');
        yield put(dashboardApiResponseSuccess(DashboardActionTypes.GET_TOP_PRODUCTS, response.data));
    } catch (error: any) {
        yield put(dashboardApiResponseError(payload.type, error));
    }
}

function* getGrowthDataAnalyticsEvent(payload: { payload: any, type: DashboardActionTypes }): SagaIterator {
    try {
        const response = yield call(getAnalyticDataApi, payload.payload, 'growth');
        yield put(dashboardApiResponseSuccess(DashboardActionTypes.GET_GROWTH_DATA, response.data));
    } catch (error: any) {
        yield put(dashboardApiResponseError(payload.type, error));
    }
}

function* getDiscountsAppliedAnalyticsEvent(payload: { payload: any, type: DashboardActionTypes }): SagaIterator {
    try {
        const response = yield call(getAnalyticDataApi, payload.payload, 'discountsApplied');
        yield put(dashboardApiResponseSuccess(DashboardActionTypes.GET_DISCOUNTS_APPLIED, response.data));
    } catch (error: any) {
        yield put(dashboardApiResponseError(payload.type, error));
    }
}

function* getEwizFeeAnalyticsEvent(payload: { payload: any, type: DashboardActionTypes }): SagaIterator {
    try {
        const response = yield call(getAnalyticDataApi, payload.payload, 'ewizFees');
        yield put(dashboardApiResponseSuccess(DashboardActionTypes.GET_EWIZ_FEES, response.data));
    } catch (error: any) {
        yield put(dashboardApiResponseError(payload.type, error));
    }
}

export function* watchGetAllAnalyticsEvents() {
    yield takeEvery(DashboardActionTypes.GET_ALL, getRsvpsAnalyticsEvent);
    yield takeEvery(DashboardActionTypes.GET_ALL, getTicketsSoldAnalytics);
    yield takeEvery(DashboardActionTypes.GET_ALL, getRevenueAnalyticsEvent);
    yield takeEvery(DashboardActionTypes.GET_ALL, getRevenueAnalyticsByEventEvent);
    yield takeEvery(DashboardActionTypes.GET_ALL, getRevenueChartAnalyticsEvent);
    yield takeEvery(DashboardActionTypes.GET_ALL, getTopProductsAnalyticsEvent);
    yield takeEvery(DashboardActionTypes.GET_ALL, getGrowthDataAnalyticsEvent);
    yield takeEvery(DashboardActionTypes.GET_ALL, getDiscountsAppliedAnalyticsEvent);
    yield takeEvery(DashboardActionTypes.GET_ALL, getEwizFeeAnalyticsEvent);
    yield takeEvery(DashboardActionTypes.GET_ALL, getRefundAnalyticsEvent);
    yield takeEvery(DashboardActionTypes.GET_ALL, getCheckInsEvent);
}

export function* watchGetStatsEvent() {
    yield takeEvery(DashboardActionTypes.GET_STATS, getRsvpsAnalyticsEvent);
    yield takeEvery(DashboardActionTypes.GET_STATS, getTicketsSoldAnalytics);
    yield takeEvery(DashboardActionTypes.GET_STATS, getRevenueAnalyticsEvent);
    yield takeEvery(DashboardActionTypes.GET_STATS, getTopProductsAnalyticsEvent);
    yield takeEvery(DashboardActionTypes.GET_STATS, getGrowthDataAnalyticsEvent);
    yield takeEvery(DashboardActionTypes.GET_STATS, getDiscountsAppliedAnalyticsEvent);
    yield takeEvery(DashboardActionTypes.GET_STATS, getEwizFeeAnalyticsEvent);
    yield takeEvery(DashboardActionTypes.GET_STATS, getRefundAnalyticsEvent);
    yield takeEvery(DashboardActionTypes.GET_STATS, getCheckInsEvent);
}

export function* watchGetRsvpStatsEvent() {
    yield takeEvery(DashboardActionTypes.GET_RSVP_STATS, getRsvpsAnalyticsEvent);
    yield takeEvery(DashboardActionTypes.GET_RSVP_STATS, getCheckInsEvent);
}

function* dashboardSaga() {
    yield all([
        fork(watchGetAllAnalyticsEvents),
        fork(watchGetStatsEvent),
        fork(watchGetRsvpStatsEvent),
    ]);
}

export default dashboardSaga;
