const groupByFields = (array: Array<any>, f: any): Array<any> => {
    /*
    params description :
        f : function which returnf the array of fields
        e.g. :  (item) => {
            return [itemField1, itemField2];
        }
        array : array of data to group e.g. : [{...}, {...}]
    */
    var groups: { [key: string]: any[] } = {};
    array.forEach((o) => {
        var group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
    });

    return Object.keys(groups).map((group) => {
        return groups[group];
    });
};

/**
 * split array into chunks
 * @param array - array to split
 * @param chunkSize - chunk size
 * @returns
 */
const splitArray = <T>(array: Array<T>, chunkSize: number) => {
    const chunks = Array(Math.ceil(array.length / chunkSize))
        .fill(1)
        .map((_, index) => index * chunkSize)
        .map((begin) => array.slice(begin, begin + chunkSize));
    return chunks;
};

const addToIndex = (items: any, callback: any) => {
    const currentLast = items.sort((a: any, b: any) => b.key - a.key);

    callback(
        [...items,
            {
                key: (currentLast[0]?.key || 0) + 1
            }
        ]
    )
}


const deleteFromIndex = (itemCurrent: any, key: number, callback: any) => {
    let items = [...itemCurrent];
    const idxToRemove = items.map((item: any) => item.key).indexOf(key);

    items.splice(idxToRemove, 1);

    callback(items);
}

export { groupByFields, splitArray, addToIndex, deleteFromIndex };
