import {APICore} from "./apiCore";

const api = new APICore();

export function getUsersApi() {
    const baseUrl = '/admin/users';
    return api.get(`${baseUrl}`, null);
}

export function getRsvpsApi(range: any) {
    const baseUrl = '/admin/rsvps';
    return api.get(`${baseUrl}`, range);
}

export function getStripeAccountsApi() {
    const baseUrl = '/admin/stripeAccounts';
    return api.get(`${baseUrl}`, null);
}

export function deleteStripeAccountsApi(id: any) {
    const baseUrl = '/admin/stripeAccounts/delete';
    return api.create(`${baseUrl}`, {id});
}

export function updateRsvpApi(rsvp: {id: any, data: any}) {
    const baseUrl = '/admin/rsvps/update';
    return api.create(`${baseUrl}`, {rsvp});
}

export function updateUserApi(data: any) {
    const baseUrl = '/admin/users/update';
    return api.create(`${baseUrl}`, {data});
}

export function getRentalsApi() {
    const baseUrl = '/rentals/all';
    return api.get(`${baseUrl}`, null);
}

export function updateRentalsEventApi(data: any) {
    const baseUrl = '/admin/rentals';
    return api.create(`${baseUrl}`, {rentals: data});
}

export function adminActionApi(data: any, type: any) {
    const baseUrl = '/admin/action';
    return api.create(`${baseUrl}`, {data, type});
}

