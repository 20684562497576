import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Events from "./events/reducers";
import Account from "./account/reducers";
import Admin from "./admin/reducers";
import Dashboard from "./dashboard/reducers";
import Reservation from './reservation/reducers';

export default combineReducers({
    Auth,
    Layout,
    Events,
    Account,
    Admin,
    Dashboard,
    Reservation,
});
