import {EventActionTypes} from "./constats";
import {EventActionType} from "./reducers";

export const eventApiResponseSuccess = (actionType: string, data: {}): EventActionType => ({
    type: EventActionTypes.API_RESPONSE_SUCCESS,
    payload: {actionType, data},
});

export const eventApiResponseError = (actionType: string, error: string): EventActionType => ({
    type: EventActionTypes.API_RESPONSE_ERROR,
    payload: {actionType, error},
});

export const getEvents = (): EventActionType => ({
    type: EventActionTypes.GET_EVENTS,
    payload: {}
})

export const getEvent = (id: string): EventActionType => ({
    type: EventActionTypes.GET_EVENT,
    payload: {id}
})

export const resetEvent = (): EventActionType => ({
    type: EventActionTypes.RESET,
    payload: {}
})

export const resetRsvp = (): EventActionType => ({
    type: EventActionTypes.RESET_RSVP,
    payload: {}
})

export const createEvent = (data: any): EventActionType => ({
    type: EventActionTypes.CREATE_EVENT,
    payload: data,
})

export const editEvent = (data: any): EventActionType => ({
    type: EventActionTypes.EDIT_EVENT,
    payload: data
})

export const getPublicEvent = (id: string): EventActionType => ({
    type: EventActionTypes.GET_PUBLIC_EVENT,
    payload: {id}
})

export const getSessionInformation = (id: string, cartId: string): EventActionType => ({
    type: EventActionTypes.GET_CART,
    payload: {id, cartId}
})

export const resetLoading = (): EventActionType => ({
    type: EventActionTypes.RESET_LOADING,
    payload: null
})

export const createRsvp = (data: any): EventActionType => ({
    type: EventActionTypes.CREATE_RSVP,
    payload: data
})

export const purchaseTickets = (data: any): EventActionType => ({
    type: EventActionTypes.CHECKOUT,
    payload: data
})

export const updateCart = (data: any): EventActionType => ({
    type: EventActionTypes.UPDATE_CART,
    payload: data
})

export const updateAdminCart = (data: any): EventActionType => ({
    type: EventActionTypes.UPDATE_ADMIN_CART,
    payload: {...data, action: 'update'}
})

export const sendAdminPaymentLink = (data: any): EventActionType => ({
    type: EventActionTypes.SEND_PAYMENT_ADMIN,
    payload: {...data, action: 'sendLink'}
})

export const adminCartCheckout = (data: any): EventActionType => ({
    type: EventActionTypes.CHECKOUT_ADMIN_CART,
    payload: {...data, action: 'checkout'}
})

export const checkinForEvent = (data: any): EventActionType => ({
    type: EventActionTypes.EVENT_CHECKIN,
    payload: data
})

export const checkDiscount = (data: any): EventActionType => ({
    type: EventActionTypes.CHECK_DISCOUNT,
    payload: data
})

export const removeDiscountCode = (data: any): EventActionType => ({
    type: EventActionTypes.REMOVE_DISCOUNT,
    payload: data
})

export const duplicateEvent = (id: any): EventActionType => ({
    type: EventActionTypes.DUPLICATE,
    payload: {id}
})

export const getRentals = (data: any): EventActionType => ({
    type: EventActionTypes.GET_RENTALS,
    payload: data
})

export const rentalCheckout = (data: any): EventActionType => ({
    type: EventActionTypes.RENTAL_CHECKOUT,
    payload: data
})

export const getPickupInfo = (data: any): EventActionType => ({
    type: EventActionTypes.GET_PICKUP_INFO,
    payload: data
})

export const getRental = (data: any): EventActionType => ({
    type: EventActionTypes.GET_RENTAL,
    payload: data
})

export const checkOutRental = (data: any): EventActionType => ({
    type: EventActionTypes.CHECKOUT_RENTAL,
    payload: data
})

export const searchRsvp = (data: any): EventActionType => ({
    type: EventActionTypes.RSVP_SEARCH,
    payload: data
})

export const getTimeBasedRsvp = (data: any): EventActionType => ({
    type: EventActionTypes.GET_TIMEBASED_RSVP,
    payload: data
})

export const resetSearch = (): EventActionType => ({
    type: EventActionTypes.RESET_SEARCH,
    payload: null
})

export const getCustomerData = (data: any): EventActionType => ({
    type: EventActionTypes.FETCH_CUSTOMER_DATA,
    payload: data
})

export const addToWaitlist = (data: any): EventActionType => ({
    type: EventActionTypes.ADD_TO_WAITLIST,
    payload: data
})

export const resetWaitlist = (): EventActionType => ({
    type: EventActionTypes.RESET_WAITLIST,
    payload: null
})
