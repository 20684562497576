import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {AccountActionTypes} from "./constants";
import {SagaIterator} from "@redux-saga/core";
import {accountApiResponseError, accountApiResponseSuccess, reset} from "./actions";
import {
    checkShopifyApi,
    connectShopifyApi,
    createCompanyApi,
    getAccountLinkApi,
    getShopifyProductsApi,
    updateCompanyApi
} from "../../helpers";
import {APICore} from "../../helpers/api/apiCore";
import {AuthActionTypes} from "../auth/constants";

const api = new APICore();

function* createCompanyEvent(payload: { payload: any, type: AccountActionTypes }): SagaIterator {
    try {
        const response = yield call(createCompanyApi, payload.payload);
        api.setLoggedInUser({
            ...api.getLoggedInUser(),
            company: response.data.company,
        });
        window.location.href = response.data.accountLink.url;
        yield put(accountApiResponseSuccess(AccountActionTypes.CREATE_COMPANY, response.data));
    } catch (error: any) {
        yield put(accountApiResponseError(AccountActionTypes.CREATE_COMPANY, error));
    }
}

function* updateCompanyEvent(payload: { payload: any, type: AccountActionTypes }): SagaIterator {
    try {
        const response = yield call(updateCompanyApi, payload.payload);
        api.setLoggedInUser({
            ...api.getLoggedInUser(),
            company: payload.payload,
        });
        yield put(accountApiResponseSuccess(AccountActionTypes.UPDATE_COMPANY, {company: payload.payload, redirect: response.data.url}));
    } catch (error: any) {
        yield put(accountApiResponseError(AccountActionTypes.UPDATE_COMPANY, error));
    }
}

function* getAccountLinkEvent(payload: {payload: string, type: AccountActionTypes}): SagaIterator {
    try {
        const response = yield call(getAccountLinkApi, payload.payload);
        window.location.href = response.data.accountLink.url;
        yield put(accountApiResponseSuccess(AccountActionTypes.GET_ACCOUNT_LINK, response.data));
    } catch (error: any) {
        yield put(accountApiResponseError(AccountActionTypes.GET_ACCOUNT_LINK, error));
    }
}

function* getConnectShopifyEvent(payload: {payload: any, type: AccountActionTypes}): SagaIterator {
    try {
        const response = yield call(connectShopifyApi, payload.payload);
        const loggedInUser = api.getLoggedInUser();
        api.setUserInSession({...loggedInUser, company: {
            ...loggedInUser.company,
                shopifyToken: response.data.token,
                store: response.data.store
            }})
        yield put(accountApiResponseSuccess(AccountActionTypes.CONNECT_SHOPIFY, response.data));
    } catch (error: any) {
        yield put(accountApiResponseError(AccountActionTypes.CONNECT_SHOPIFY, error));
    }
}

function* getCheckShopifyEvent(): SagaIterator {
    try {
        const response = yield call(checkShopifyApi);
        yield put(accountApiResponseSuccess(AccountActionTypes.CHECK_SHOPIFY, response.data));
    } catch (error: any) {
        yield put(accountApiResponseError(AccountActionTypes.CHECK_SHOPIFY, error));
    }
}

function* getShopifyProductsEvent(): SagaIterator {
    try {
        const response = yield call(getShopifyProductsApi);
        yield put(accountApiResponseSuccess(AccountActionTypes.GET_SHOPIFY_PRODUCTS, response.data));
    } catch (error: any) {
        yield put(accountApiResponseError(AccountActionTypes.GET_SHOPIFY_PRODUCTS, error));
    }
}

function* resetEvent(): SagaIterator {
    yield put(reset());
}

export function* watchCreateCompanyEvent() {
    yield takeEvery(AccountActionTypes.CREATE_COMPANY, createCompanyEvent)
}

export function* watchUpdateCompanyEvent() {
    yield takeEvery(AccountActionTypes.UPDATE_COMPANY, updateCompanyEvent)
}

export function* watchLogout() {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, resetEvent);
}


export function* watchGetAccountLink() {
    yield takeEvery(AccountActionTypes.GET_ACCOUNT_LINK, getAccountLinkEvent);
}

export function* watchCheckShopifyEvent() {
    yield takeEvery(AccountActionTypes.CHECK_SHOPIFY, getCheckShopifyEvent);
}

export function* watchConnectShopifyEvent() {
    yield takeEvery(AccountActionTypes.CONNECT_SHOPIFY, getConnectShopifyEvent);
}

export function* watchGetShopifyProductsEvent() {
    yield takeEvery(AccountActionTypes.GET_SHOPIFY_PRODUCTS, getShopifyProductsEvent);
}

function* accountSaga() {
    yield all([
        fork(watchCreateCompanyEvent),
        fork(watchLogout),
        fork(watchGetAccountLink),
        fork(watchUpdateCompanyEvent),
        fork(watchCheckShopifyEvent),
        fork(watchConnectShopifyEvent),
        fork(watchGetShopifyProductsEvent),
    ]);
}

export default accountSaga;
