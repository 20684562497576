import {ReservationActionTypes} from "./constants";
import {errorMessageHandler} from "../../utils";

export const RESERVATION_KEY = 'ewiz_rez';
export const RESERVATION_ID = 'ewiz_rez_id';
const INIT_STATE = {
    loading: false,
    eventDetails: {},
};

type State = {
    reservation?: any;
    loading?: boolean;
}

export type ReservationActionType = {
    type: ReservationActionTypes.API_RESPONSE_ERROR
        | ReservationActionTypes.API_RESPONSE_SUCCESS
        | ReservationActionTypes.SET_RESERVATION_ID
        | ReservationActionTypes.CONFIRM_AUTH_CODE
        | ReservationActionTypes.RESET_MANAGE
        | ReservationActionTypes.MANAGE_RESERVATION
        | ReservationActionTypes.ACCEPT_TRANSFER
        | ReservationActionTypes.GET_RESERVATION;
    payload: {
        actionType?: string;
        data?: any;
        error?: string;
    } | any
}
export default function Reservation(state: State = INIT_STATE, action: ReservationActionType) {
    switch (action.type) {
        case ReservationActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ReservationActionTypes.GET_RESERVATION: {
                    return {
                        ...state,
                        reservation: action.payload.data.reservation,
                        eventDetails: action.payload.data.eventDetails,
                        loading: false,
                    }
                }
                case ReservationActionTypes.CONFIRM_AUTH_CODE: {
                    return {
                        ...state,
                        successfulConfirmation: true,
                        loading: false,
                    }
                }
                case ReservationActionTypes.SET_RESERVATION_ID: {
                    return {
                        ...state,
                        id: action.payload.data.id,
                        loading: false,
                    }
                }
                case ReservationActionTypes.MANAGE_RESERVATION: {
                    return {
                        ...state,
                        data: action.payload.data,
                        loading: false,
                        reservation: action.payload.data.rsvp || state.reservation,
                    }
                }
                case ReservationActionTypes.ACCEPT_TRANSFER: {
                    return {
                        ...state,
                        successfulConfirmation: true,
                        loading: false,
                    }
                }
                default:
                    return {...state, loading: false}
            }
        case ReservationActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {...state, loading: false, error: errorMessageHandler(action.payload.error)}
            }
        case ReservationActionTypes.GET_RESERVATION:
            return {
                ...state,
                loading: true,
            }
        case ReservationActionTypes.SET_RESERVATION_ID:
            return {
                loading: true,
            }
        case ReservationActionTypes.CONFIRM_AUTH_CODE:
            return {
                ...state,
                loading: true,
            }
        case ReservationActionTypes.MANAGE_RESERVATION:
            return {
                ...state,
                loading: true,
            }
        case ReservationActionTypes.ACCEPT_TRANSFER:
            return {
                ...state,
                loading: true,
            }
        case ReservationActionTypes.RESET_MANAGE:
            return {
                ...state,
                data: {}
            }
        default:
            return {...state};
    }
}
