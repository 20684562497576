const config = {
    API_URL: process.env.REACT_APP_API_URL,
    GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    COGNITO: process.env.REACT_APP_COGNITO_ID,
    SECURE_LOCAL_STORAGE_HASH_KEY: process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY,
    SECURE_LOCAL_STORAGE_PREFIX: process.env.REACT_APP_SECURE_LOCAL_STORAGE_PREFIX
};

export default config;
